body {
  direction: rtl;
}

main,
header,
nav,
footer,
section,
aside,
article {
  display: block;
}

ul {
  list-style: none
}

* {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;

}

#logo {
  background-image: url("./images/logo.png");
  background-size: 85% 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 200px;
  height: 100%;
  display: block;
}




nav a {
  text-decoration: none;
}

nav ul {
  list-style: none;
}

nav * {
  padding: 0;
  margin: 0;
}

nav {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.nav_wrapper {
  display: flex;
  max-width: 94%;
  margin: 0 auto;


}

/* header nav::after {
  content: "";
  display: block;
  height: 10px;
  width: 100%;
  background-image: url("images/nav_border.PNG");
  background-position: center bottom;
} */

.logo_container {
  flex: 1;
  background: transparent;
  min-height: 70px;

  display: flex;
}

.logo {
  background-image: url("images/logo.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: block;
  width: 230px;
  height: 45px;
  margin: auto 0;
}

.main_nav {
  flex: 5;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

}

.main_nav>li>a {

  color: rgba(0, 0, 0, 0.863);
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin: auto 8px;
  letter-spacing: 0.75px;
  display: inline-block;
  transition: 0.04s ease-in;
  opacity: 0.9;
  padding: 10px;
  border-radius: 4px;
}

.main_nav>li {
  display: flex;
  position: relative;
  height: 100%;

  flex-direction: column;

}

.main_nav>li:hover>a {
  /* border-bottom: 3px solid green; */
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.07);


}



.main_nav>li ul {
  display: none;
}


.main_nav>li:hover>ul {
  display: block;
  margin-top: 50px;
  min-width: 130px;
  white-space: nowrap;
  right: 0;
  position: absolute;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 10px 0px;
}

.main_nav>li>ul>li {
  padding: 10px;
  cursor: pointer;
}

.main_nav>li>ul>li>a {
  color: rgba(0, 0, 0, 0.863);
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin: auto 10px;
  letter-spacing: 0.75px;

  transition: 0.04s ease-in;
  list-style: none;

  border-radius: 4px;
}

.main_nav>li>ul>li:hover {
  background: #f5f5f5;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.07)
}

#nav_selected {
  background-color: rgba(0, 0, 0, 0.07);
  color: black;
  opacity: 1;
}

#nav_container>li .selectedLink {
  background-color: rgba(0, 0, 0, 0.07);
  color: black;
  opacity: 1;
}

#nav_container>li>ul .selectedLinkInner {
  background: #f5f5f5;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.07)
}


/** hamburger**/
#hamburger {
  display: none;
  position: relative;
  top: 25px;
  left: 10px;
  z-index: 6;
  -webkit-user-select: none;
  user-select: none;
}

#hamburger input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  right: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 7;
  -webkit-touch-callout: none;
}

#hamburger span {
  display: block;
  width: 23.5px;
  height: 3px;
  margin-bottom: 3px;
  position: relative;
  background: #464646;
  border-radius: 4px;
  z-index: 1;
  transform-origin: 3px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
}

#hamburger span:first-child {
  transform-origin: 0% 0%;
}

#hamburger span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/*hamburger animation*/

#hamburger input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #464646;
}

#hamburger input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#hamburger input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#hamburger_menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  padding-top: 10px;
  padding-inline-start: 0px;
  background: transparent;
  transform-origin: 0% 0%;
  transform: translate(0, -1200%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

}

#hamburger_menu>li:first-child>a {
  display: block;
  /*  background: url("images/logo.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 50px;
  width: 235px;
  margin-bottom: 10px;
  margin-right: 15px;


}

#hamburger_menu>li:not(:first-child) {
  background: white;
}


#hamburger_menu>li:not(:first-child)>a {

  color: rgba(37, 37, 37, 0.753);
  display: block;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.75px;
  display: inline-block;

  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  padding-right: 25px;
  border-bottom: 1px solid rgba(221, 221, 221, 0.425);
  overflow: auto;
}


#hamburger_menu>li:not(:first-child) {
  background: white;
}

#hamburger_menu>li:not(:first-child):hover>a {
  background: rgba(0, 0, 0, 0.07);
  color: black !important;
  opacity: 1;
}

#hamburger_menu>li>ul>li:hover>a {
  background: rgba(0, 0, 0, 0.15);
  color: black !important;
  opacity: 1;
}

#hamburger_menu li .selectedLinkBurger {
  background: rgba(0, 0, 0, 0.07);
  color: black !important;
  opacity: 1;
}

#hamburger_menu>li>ul>.selectedLinkBurgerInner a {
  background: rgba(0, 0, 0, 0.15);
  color: black !important;
  opacity: 1;
}

#hamburger_menu li a svg:last-child {
  position: absolute;
  left: 25px;
}

#hamburger input:checked~ul {
  transform: none;
}





#hamburger_menu>li>ul>li>a {
  color: rgba(37, 37, 37, 0.753);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.75px;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  padding-right: 25px;
  border-bottom: 1px solid rgba(221, 221, 221, 0.425);
  display: block;
  overflow: auto;
}

#hamburger_menu>li>ul>li>a::before {
  content: " - ";
}

#hamburger_menu>li>ul>li:last-child>a {
  border-bottom: none;
}

#hamburger_menu>li>ul {
  display: none;
  border-bottom: 1px solid rgba(221, 221, 221, 0.425);
}

#hamburger_menu>li:hover ul,
#hamburger_menu>li:active ul {
  display: block;

}

@media only screen and (max-width: 1030px) {
  .main_nav {
    display: none;
  }

  .logo {

    /*  overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0; */
    height: 50px;
    width: 235px;
  }

  .logo_container {
    margin: 0;
  }

  .nav_wrapper {
    max-width: 98%;
  }

  #hamburger {
    display: block;
  }

  #hamburger_menu {
    display: block;
  }

  .hamburger_nav {
    display: flex;

    flex: 1;
    justify-content: flex-end;
  }



}



/* //////////////  home */
.Carousel .Button {
  background: black;
  opacity: 0.4 !important;

}

.Carousel .Button:hover {
  opacity: 0.8 !important;
}




/* my carusal */





/* projects */

.collapsedd {
  height: 0;
  overflow: hidden;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;

}

.sectionn {
  color: whitesmoke;
  background: rgba(0, 0, 0, 0.8);
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* transition: "all 3s ease-out", */
  padding: 5px;
  cursor: pointer;
}

.sectionn:hover .collapsedd {


  display: block;
  height: 130px;



}

.collapsedd p {
  max-height: 65px;
  overflow: hidden;
}

.tetststts {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  direction: rtl;
  list-style: none;
  text-align: center;
  padding: 0px;
  right: 10%;
  left: 10%;
  bottom: auto;
  margin: auto;
  position: absolute;
}